/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo } from "react";
import { scaleTime, scaleBand } from "@visx/scale";
import { Grid } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { utcFormat } from "d3-time-format";
import { Bar } from "@visx/shape";
import { Annotation } from "@visx/annotation";
import { VulnerabilitySpan } from "../../../../../types/dashboard";

// Initialize some variables
export const accentColor = "#b7b7a4";
export const background = "#F18D04";
export const axisBottomTickLabelProps = {
  dy: "1em",
  textAnchor: "middle" as const,
  fontFamily: "Arial",
  fontSize: 10,
  fill: "#FFF",
};

const VulnerabilitySpans = ({
  vulnerabilitiesSpan,
  compact = false,
  width,
  height,
  margin = {
    top: 0,
    left: 50,
    bottom: 30,
    right: 100,
  },
}: {
  vulnerabilitiesSpan: VulnerabilitySpan[];
  compact?: boolean;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
}) => {
  const innerHeight = height - margin.top - margin.bottom;

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);

  // band values
  const vulnerabilities = vulnerabilitiesSpan.reduce(
    (pV: number[], cV) => [...pV, cV.id],
    []
  );

  const earliest = vulnerabilitiesSpan.reduce(
    (pV: number[], cV) => [...pV, cV.earliest_scan_time],
    []
  );
  const latest = vulnerabilitiesSpan.reduce(
    (pV: number[], cV) => [...pV, cV.latest_scan_time],
    []
  );

  // scales

  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: [
          new Date(Math.min(...earliest)),
          new Date(Math.max(...latest) + 8.64e7),
        ],
      }),
    [xMax, vulnerabilitiesSpan]
  );
  const dataScale = useMemo(
    () =>
      scaleBand({
        range: [innerHeight, 0],
        domain: vulnerabilities,
        paddingOuter: 0,
        paddingInner: 1,
      }),
    [vulnerabilitiesSpan]
  );

  return (
    <section className="relative grid grid-cols-1">
      <svg width={width} height={height}>
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={dataScale}
          width={xMax}
          height={innerHeight}
          numTicksRows={8}
          stroke="#253D55"
          strokeOpacity={0.5}
        />
        <AxisBottom
          top={innerHeight}
          left={margin.left}
          scale={dateScale}
          numTicks={5}
          stroke="#FFF"
          tickStroke="#FFF"
          tickLabelProps={() => axisBottomTickLabelProps}
          tickFormat={(v: any) => utcFormat("%b %d")(v)}
        />

        {vulnerabilitiesSpan?.map((scan: VulnerabilitySpan, index: number) => {
          const windowStart = scan.earliest_scan_time;
          const windowEnd =
            scan.earliest_scan_time === scan.latest_scan_time
              ? scan.latest_scan_time + 8.64e7
              : scan.latest_scan_time;

          const daysOpen = Math.floor((windowEnd - windowStart) / 8.64e7);

          return (
            <Annotation key={index} x={500} y={90} dx={0} dy={-15}>
              <Fragment key={index}>
                <text
                  x={dateScale(new Date(windowStart))}
                  y={innerHeight - index * 30 - 17}
                  fontSize={10}
                  className="grid dark:text-white"
                  stroke="#FFF"
                  strokeWidth={0.5}
                  fill="#FFF"
                >
                  {scan.name} ({daysOpen} day{daysOpen !== 1 && "s"})
                </text>
                <Bar
                  key={windowStart}
                  x={dateScale(new Date(windowStart)) + margin.left}
                  y={innerHeight - index * 30 - 10}
                  width={
                    dateScale(new Date(windowEnd)) -
                    dateScale(new Date(windowStart))
                  }
                  height={2}
                  fill={scan[3] === "HIGH" ? "#FF0000" : "#870A0A"}
                  opacity={1}
                  style={{ cursor: "pointer" }}
                />
              </Fragment>
            </Annotation>
          );
        })}
      </svg>
    </section>
  );
};

export default VulnerabilitySpans;
