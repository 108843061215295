import React from "react";
import { convertToUTCString } from "src/utils/general";
import { attributeColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors-and-partners/vendors";

const VendorMetadata = ({ vendorID }: { vendorID: string }) => {
  const { data: vendorMetadata } = GetVendorMetadata(vendorID);

  return (
    <>
      {vendorMetadata && (
        <header className="grid gap-3">
          <article className="flex flex-wrap items-center justify-between gap-20 border-b-1 dark:border-white">
            <article className="flex items-center gap-2 divide-x dark:divide-white">
              <h2 className="text-2xl">{vendorMetadata.name}</h2>
              {vendorMetadata.address && (
                <p className="pl-2 mt-2 italic text-sm">
                  {vendorMetadata.address}
                </p>
              )}
            </article>
            <article className="flex flex-wrap items-center gap-3">
              <article className="flex items-center gap-2">
                <h4 className="dark:text-checkbox">last updated at</h4>
                <span>{convertToUTCString(vendorMetadata.last_updated)}</span>
              </article>
            </article>
          </article>
          <article className="flex items-center gap-10">
            <article className="flex items-center gap-2">
              <h4>Risk Profile</h4>
              <span
                className={`capitalize ${
                  attributeColors[vendorMetadata.risk_profile.toLowerCase()]
                }`}
              >
                {vendorMetadata.risk_profile}
              </span>
            </article>
            <article className="flex items-center gap-2">
              <h4>Assessments</h4>
              <span className="px-4 py-2 dark:bg-signin rounded-md">
                {vendorMetadata.number_of_assessments}
              </span>
            </article>
          </article>
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
