import React, { useState } from "react";
import StackedBarChart from "src/components/Chart/StackedBarChart";
import { GetVulnCountByService } from "src/services/summaries/application-footprint";
import { useSummaryStore } from "src/stores/summaries";

const VulnerabilityCountByService = () => {
  const { period, selectedReportAccount } = useSummaryStore();

  const [sectionProps, setSectionProps] = useState({});

  const { data: vulnCountByService } = GetVulnCountByService(
    period,
    selectedReportAccount?.integration_type || "",
    selectedReportAccount?.customer_cloud_id || ""
  );

  return (
    <section className="grid content-start gap-5 p-6 w-full h-full dark:bg-card">
      <h4>Vulnerability Count by Service</h4>
      <StackedBarChart
        data={vulnCountByService}
        xKey="service"
        xLabel="Service"
        yLabel="Count"
        sectionProps={sectionProps}
        setSectionProps={setSectionProps}
      />
    </section>
  );
};

export default VulnerabilityCountByService;
