/* eslint-disable react-hooks/exhaustive-deps */
import { GetSOPTagNamesAndValues } from "src/services/business-continuity/sop";

const AllTags = ({
  selectedTags,
  setSelectedTags,
}: {
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
}) => {
  const { data: sopTags } = GetSOPTagNamesAndValues();

  return (
    <ul className="flex flex-wrap items-center gap-3">
      Department
      {sopTags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {sopTags.map((tag: string, index: number) => {
            return (
              <li
                key={index}
                className={`flex items-center gap-3 px-3 py-1 cursor-pointer dark:text-white ${
                  selectedTags.includes(tag)
                    ? "dark:bg-signin/80"
                    : "dark:bg-org dark:hover:bg-org/60 duration-100"
                } rounded-full`}
                onClick={() => {
                  if (selectedTags.includes(tag)) setSelectedTags([]);
                  else setSelectedTags([tag]);
                }}
              >
                <p>{tag}</p>
              </li>
            );
          })}
        </ul>
      )}
    </ul>
  );
};

export default AllTags;
