import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const prefix = "grc/tprm/third-parties";

export const GetVendorsAndPartners = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendors-and-partners"],
    async ({ signal }) => {
      try {
        const res = await client.get(`/api/${apiVersion}/${prefix}`, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddVendor = () =>
  useMutation<any, unknown, any, string>(
    async ({
      name,
      riskProfile,
      address,
      signal,
    }: {
      name: string;
      riskProfile: string;
      address: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}`,
          { name: name, risk_profile: riskProfile, address: address },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-and-partners"]);
      },
    }
  );

export const EditVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      name,
      riskProfile,
      address,
      lastUpdated,
      numAssessments,
      analyst,
      signal,
    }: {
      name: string;
      riskProfile: string;
      address: string;
      lastUpdated: number;
      numAssessments: number;
      analyst: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${vendorID}`,
          {
            name: name,
            risk_profile: riskProfile,
            address: address,
            last_updated: lastUpdated,
            number_of_assessments: numAssessments,
            analyst: analyst,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-and-partners"]);
      },
    }
  );

export const GetVendorMetadata = (vendorID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-vendor-metadata", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${vendorID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const SearchVendor = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ vendor, signal }: { vendor: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/searches`,
          {
            third_party_name: vendor,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorAssessments = (
  vendorID: string,
  order: KeyStringVal,
  pageNumber: number,
  category?: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-assessments", order, pageNumber, category],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/tprm/assessments`,
          {
            third_party_id: vendorID,
            ...(order.order_by !== "" && { order: order }),
            page_number: pageNumber,
            page_size: pageSize,
            ...(category && { category: category }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );
