/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { MutableRefObject, useEffect, useRef } from "react";
import ViewInGraph from "src/components/Button/ViewInGraph";
import { KeyStringVal } from "src/types/general";
import { AffectedImage, AffectedRepo } from "../../../../../types/dashboard";
import { parseURL } from "../../../../../utils/general";
import { useGeneralStore } from "src/stores/general";
import { GetImageScans, GetCVEInfo } from "src/services/dashboard/infra";

const VulnerabilityTree = ({
  selectedNodeID,
  curCVE,
  selectedRepo,
  setSelectedRepo,
  selectedImage,
  setSelectedImage,
}: {
  selectedNodeID: string;
  curCVE: string;
  selectedRepo: string;
  setSelectedRepo: (selectedRepo: string) => void;
  selectedImage: string | undefined;
  setSelectedImage: (selectedImage: string | undefined) => void;
}) => {
  const parsed = parseURL();

  const { env } = useGeneralStore();

  const repoRef = useRef([]) as MutableRefObject<any[]>;
  const imageRef = useRef([]) as MutableRefObject<any[]>;

  const { data: imageScans } = GetImageScans(
    env,
    parsed.integration,
    encodeURIComponent(encodeURIComponent(selectedNodeID)),
    parsed.node_type
  );
  const { data: cveInfo } = GetCVEInfo(
    env,
    parsed.integration,
    curCVE,
    parsed.node_type
  );

  const repos = cveInfo?.cve.affected_repositories;

  const images = repos?.find(
    (repo: AffectedRepo) => repo.name === selectedRepo
  )?.images;

  const containers =
    cveInfo && Object.keys(cveInfo.containers).length > 0
      ? cveInfo?.containers[`${selectedRepo}:${selectedImage}`]
      : [];

  const curRepoName = imageScans?.repository_name;
  const curImageTag = imageScans?.image_tag;

  useEffect(() => {
    if (repos && curRepoName && selectedRepo === "") {
      const curRepo = repos.find(
        (repo: AffectedRepo) => repo.name === curRepoName
      );
      if (curRepo) setSelectedRepo(curRepo.name);
      else if (repos.length > 0) setSelectedRepo(repos[0].name);

      if (repoRef?.current) {
        const repoIndex = repos.findIndex(
          (repo: AffectedRepo) => repo.name === curRepoName
        );
        repoRef.current[repoIndex]?.scrollIntoView();
      }
    }
  }, [repos, curRepoName, selectedRepo]);

  useEffect(() => {
    if (repos && curRepoName && curImageTag && selectedRepo !== "") {
      const imageTags = repos.find(
        (repo: AffectedRepo) => repo.name === curRepoName
      )?.images;
      const curImage = imageTags?.find(
        (imageTag: KeyStringVal) => imageTag.image_tag === curImageTag
      ).image_sha;

      if (selectedRepo === curRepoName && curImage) setSelectedImage(curImage);
      else
        setSelectedImage(
          repos.find((repo: AffectedRepo) => repo.name === selectedRepo)
            ?.images[0].image_sha
        );

      if (imageRef?.current) {
        const imageIndex = imageTags?.findIndex(
          (imageTag: KeyStringVal) => imageTag.image_tag === curImageTag
        );
        imageRef.current[imageIndex]?.scrollIntoView();
      }
    }
  }, [selectedRepo]);

  return (
    <section className="grid gap-3 content-start text-sm">
      <h4 className="dark:text-checkbox">Vulnerability Tree</h4>
      <article className="grid grid-cols-3 items-center">
        <article className="relative grid content-start">
          <h4 className="dark:text-checkbox mb-2">Image Group ID</h4>
          <ul className="grid content-start gap-1 h-60 overflow-y-auto no-scrollbar divide-y dark:divide-panel">
            {repos?.map((repo: AffectedRepo, index: number) => {
              return (
                <li
                  key={`${repo.name}-${index}`}
                  ref={(el) => (repoRef.current[index] = el)}
                  className="flex items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRepo(repo.name);
                  }}
                >
                  <article
                    className={`grid gap-1 w-full break-all px-2 py-1 cursor-pointer ${
                      selectedRepo === repo.name
                        ? "selected-button"
                        : repo.active_container
                        ? "dark:bg-no/30 dark:hover:bg-no/60 duration-100 border dark:border-no"
                        : "dark:bg-filter/30 dark:hover:bg-filter/60 duration-100 border dark:border-filter"
                    }`}
                  >
                    <p>{repo.name}</p>
                    <ViewInGraph
                      requestData={{
                        query_type: "view_in_graph",
                        id: repo.name,
                      }}
                    />
                  </article>
                  <svg
                    width="30"
                    height="1"
                    viewBox="0 0 30 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="0.5"
                      x2="50"
                      y2="0.5"
                      stroke={repo.active_container ? "#22B573" : "#445C75"}
                    />
                  </svg>
                </li>
              );
            })}
          </ul>
        </article>
        <article className="relative grid content-start">
          <h4 className="dark:text-checkbox mb-2 ml-8">Images</h4>
          <ul className="grid content-start gap-1 h-60 overflow-y-auto no-scrollbar">
            {images?.map((imageTag: AffectedImage, index: number) => {
              return (
                <li
                  key={index}
                  ref={(el) => (imageRef.current[index] = el)}
                  className="relative flex items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedImage(imageTag.image_sha);
                  }}
                >
                  <svg
                    width="30"
                    height="1"
                    viewBox="0 0 30 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="0.5"
                      x2="50"
                      y2="0.5"
                      stroke={imageTag.active_container ? "#22B573" : "#445C75"}
                    />
                  </svg>
                  <article
                    className={`grid gap-1 w-full break-all px-2 py-1 cursor-pointer ${
                      selectedImage === imageTag.image_sha
                        ? "selected-button"
                        : imageTag.active_container
                        ? "dark:bg-no/30 dark:hover:bg-no/60 duration-100 border dark:border-no"
                        : "dark:bg-filter/30 dark:hover:bg-filter/60 duration-100 border dark:border-filter"
                    }`}
                  >
                    <p>{imageTag.image_sha.slice(0, 14)}</p>
                    <p className="break-all">Tag: {imageTag.image_tag}</p>
                    <ViewInGraph
                      requestData={{
                        query_type: "view_in_graph",
                        id: `${selectedImage}:${imageTag.image_sha}`,
                      }}
                    />
                  </article>
                  {selectedImage === imageTag.image_sha &&
                    containers?.length > 0 && (
                      <svg
                        width="30"
                        height="1"
                        viewBox="0 0 30 1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          y1="0.5"
                          x2="50"
                          y2="0.5"
                          stroke={
                            imageTag.active_container ? "#22B573" : "#445C75"
                          }
                        />
                      </svg>
                    )}
                </li>
              );
            })}
          </ul>
        </article>
        <article className="grid content-start">
          <h4 className="dark:text-checkbox mb-2 ml-8">Containers</h4>
          <ul className="grid content-start gap-1 h-60 overflow-y-auto no-scrollbar">
            {containers?.map((container: string, index: number) => {
              return (
                <li key={index} className="relative flex items-center">
                  <svg
                    width="30"
                    height="1"
                    viewBox="0 0 30 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line y1="0.5" x2="50" y2="0.5" stroke="#22B573" />
                  </svg>
                  <article className="grid gap-1 w-full break-all px-2 py-1 dark:bg-no/30 border dark:border-no">
                    <p>{container}</p>
                    <ViewInGraph
                      requestData={{
                        query_type: "view_in_graph",
                        id: container,
                      }}
                    />
                  </article>
                </li>
              );
            })}
          </ul>
        </article>
      </article>
    </section>
  );
};

export default VulnerabilityTree;
