/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Popup,
  AreaHighlight,
  Highlight,
} from "react-pdf-highlighter";
import type { ScaledPosition } from "react-pdf-highlighter";
import { KeyStringVal } from "src/types/general";
import "./style/AreaHighlight.css";
import "./style/Highlight.css";
import Loader from "src/components/Loader/Loader";
import AgreementSidePanel from "./AgreementSidePanel";
import { GetAgreement } from "src/services/contractual-obligations";
import { GetDocumentStatus } from "src/services/grc";
import { GetPdfPreview } from "src/services/risk-compliance/circular";

const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) => null;

const AgreementReview = ({ obligationID }: { obligationID: string }) => {
  const [selectedHighlight, setSelectedHighlight] = useState("");

  const pdfHighlighter = useRef<any>(null);

  const documentType = "contractual_obligations";

  const { data: documentStatus } = GetDocumentStatus(
    documentType,
    obligationID
  );
  const { data: pdfPreview } = GetPdfPreview(obligationID, "", documentType);
  const { data: agreement } = GetAgreement(obligationID);

  const url = pdfPreview?.bucket_url;
  const highlights = agreement?.data?.reduce(
    (pCat: string[], cCat: any) => [
      ...pCat,
      ...cCat.sub_categories.reduce(
        (pSub: any, cSub: any) => [
          ...pSub,
          ...cSub.questions.reduce(
            (pQ: any, cQ: any) => [
              ...pQ,
              ...cQ.sources.reduce(
                (pSource: any, cSource: any) => [...pSource, cSource],
                []
              ),
            ],
            []
          ),
        ],
        []
      ),
    ],
    []
  ) as any[];
  const filteredHighlights =
    highlights?.find(
      (highlight: KeyStringVal) => highlight.id === selectedHighlight
    )?.position || [];

  const scrollToHighlightFromHash = () => {
    const highlight = filteredHighlights?.length > 0 && filteredHighlights[0];

    if (highlight) {
      pdfHighlighter?.current?.scrollTo(highlight);
    }
  };

  useEffect(() => {
    scrollToHighlightFromHash();
  }, [selectedHighlight]);

  return (
    <>
      {documentStatus?.status === "failed" ? (
        <section className="grid place-content-center gap-10 w-full h-full text-center">
          <img src="/errors/503.svg" alt="error" className="mx-auto h-72" />
          <h4>
            Oops! something went wrong! We will reach out to you shortly to help
            resolve the issue. Thanks for your patience.
          </h4>
        </section>
      ) : documentStatus?.status === "parsing" ? (
        <article className="flex items-center place-content-center gap-5">
          <img
            src={`/grc/frameworks-placeholder.svg`}
            alt="placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4>
              Your document has been received and is currently being processed
            </h4>
            <img
              src="/grc/data-parsing.svg"
              alt="data parsing"
              className="w-10 h-10"
            />
          </article>
        </article>
      ) : (
        <section className="flex content-start gap-5 p-4 w-full h-full">
          <section
            style={{
              width: "150vw",
              position: "relative",
            }}
            className="w-full h-full dark:bg-card overflow-auto scrollbar"
          >
            <PdfLoader url={url} beforeLoad={<Loader />}>
              {(pdfDocument) => (
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  enableAreaSelection={(event) => event.altKey}
                  onScrollChange={() => {}}
                  ref={pdfHighlighter}
                  scrollRef={(scrollTo) => {
                    scrollToHighlightFromHash();
                  }}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const isTextHighlight = !Boolean(highlight.content?.image);

                    const component = isTextHighlight ? (
                      <Highlight
                        isScrolledTo={highlight.id === selectedHighlight}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <AreaHighlight
                        isScrolledTo={highlight.id === selectedHighlight}
                        highlight={highlight}
                        onChange={(boundingRect) => {}}
                      />
                    );

                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={(popupContent) =>
                          setTip(highlight, (highlight) => popupContent)
                        }
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={filteredHighlights}
                  onSelectionFinished={function (
                    position: ScaledPosition,
                    content: {
                      text?: string | undefined;
                      image?: string | undefined;
                    },
                    hideTipAndSelection: () => void,
                    transformSelection: () => void
                  ): any {}}
                />
              )}
            </PdfLoader>
          </section>
          <AgreementSidePanel
            data={agreement?.data}
            selectedHighlight={selectedHighlight}
            setSelectedHighlight={setSelectedHighlight}
          />
        </section>
      )}
    </>
  );
};

export default AgreementReview;
