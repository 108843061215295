/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { GetPolicyControlFilters } from "src/services/risk-compliance/policy";

const ControlFilter = ({
  policyID,
  label,
  keyName,
  value,
  setValue,
}: {
  policyID: string;
  label?: string;
  keyName: string;
  value: string;
  setValue: (value: string) => void;
}) => {
  const { data: controlFilters } = GetPolicyControlFilters(policyID);

  const filteredList = controlFilters && controlFilters[keyName];

  return (
    <>
      {filteredList?.length > 0 && (
        <section className="flex flex-wrap items-center gap-x-10 gap-y-5">
          <h4 className="w-12 font-medium dark:text-checkbox">{label}</h4>
          {filteredList?.map((curVal: string, index: number) => (
            <article key={index} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={curVal === value}
                className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-checkbox dark:focus:border-checkbox focus:ring dark:focus:ring-offset-0 dark:focus:ring-checkbox focus:ring-opacity-50"
                onChange={() => setValue(curVal)}
              />
              <span>{curVal}</span>
            </article>
          ))}
        </section>
      )}
    </>
  );
};

export default ControlFilter;
