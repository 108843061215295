import React, { useState } from "react";
import ObligationMetadata from "./ObligationMetadata";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AgreementReview from "./AgreementReview/AgreementReview";
import DocumentTabs from "./DocumentTabs";
import Sections from "./Sections/Sections";

const Obligation = () => {
  const parsed = parseURL();

  const [selectedTab, setSelectedTab] = useState<string>("Agreement Review");

  const obligationID = String(parsed.obligation_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-5 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-5">
          <ReturnPage />
          <h4 className="capitalize">Contractual Obligations</h4>
        </article>
        <ObligationMetadata obligationID={obligationID} />
        <DocumentTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {selectedTab === "Agreement Review" ? (
          <AgreementReview obligationID={obligationID} />
        ) : (
          <Sections obligationID={obligationID} />
        )}
      </main>
    </PageLayout>
  );
};

export default Obligation;
