import { useMutation, useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const prefix = "grc/contractual_obligations";

export const GetContractualObligations = (
  order: KeyStringVal,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal)[]>(
    ["get-contractual-obligations", order, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}`,
          {
            ...(order.order_by !== "" && { order: order }),
            page_size: pageSize,
            page_number: pageNumber,
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const ParseContractualObligationMetadata = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/parse`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const CreateObligation = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new_contractual_obligation`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetContractualObligationMetadata = (obligationID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-contractual-obligation-metadata", obligationID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${obligationID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: obligationID !== "",
      keepPreviousData: false,
    }
  );

export const GetAgreement = (obligationID: string) =>
  useQuery<any, unknown, any, (string | boolean | KeyStringVal | string[])[]>(
    ["get-agreement", obligationID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/mapping?contractual_obligation_id=${obligationID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: obligationID !== "",
      keepPreviousData: false,
    }
  );

export const GetObligationSections = (
  obligationID: string,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-obligation-sections", obligationID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/contractual_obligations/sections`,
          {
            contractual_obligation_id: obligationID,
            pager: { page_size: pageSize, page_number: pageNumber },
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: obligationID !== "",
      keepPreviousData: false,
    }
  );
