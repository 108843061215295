import { useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion } from "src/constants/general";

const prefix = "summaries/vr/vulnerability-risks";

export const GetVRSummary = (
  period: number,
  source: string,
  version: string,
  integrationType: string,
  sourceAccountID: string
) =>
  useQuery<any, unknown, any, any[]>(
    [
      "get-vr-summary",
      period,
      source,
      version,
      integrationType,
      sourceAccountID,
    ],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/summary?period=${period}&source=${source}&version=${version}&integration_type=${integrationType}&source_account_id=${sourceAccountID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      enabled:
        source !== "" &&
        version !== "" &&
        integrationType !== "" &&
        sourceAccountID !== "",
    }
  );

export const GetVRSeverityDistribution = (
  period: number,
  source: string,
  version: string,
  integrationType: string,
  sourceAccountID: string
) =>
  useQuery<any, unknown, any, any[]>(
    [
      "get-vr-severity-distribution",
      period,
      source,
      version,
      integrationType,
      sourceAccountID,
    ],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/severity_distribution?period=${period}&source=${source}&version=${version}&integration_type=${integrationType}&source_account_id=${sourceAccountID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled:
        source !== "" &&
        version !== "" &&
        integrationType !== "" &&
        sourceAccountID !== "",
      keepPreviousData: false,
    }
  );

export const GetVRCVEDetails = (
  period: number,
  cveID: string,
  source: string,
  version: string,
  integrationType: string,
  sourceAccountID: string,
  pageNumber: number,
  pageSize: number
) =>
  useQuery<any, unknown, any, any[]>(
    [
      "get-vr-cve-details",
      period,
      cveID,
      source,
      version,
      integrationType,
      sourceAccountID,
      pageNumber,
      pageSize,
    ],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/cves/${cveID}?period=${period}&source=${source}&version=${version}&integration_type=${integrationType}&source_account_id=${sourceAccountID}&page_number=${pageNumber}&page_size=${pageSize}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled:
        source !== "" &&
        version !== "" &&
        cveID !== "" &&
        sourceAccountID !== "",
      keepPreviousData: false,
    }
  );

export const GetInsightVMInfo = (
  integrationType: string,
  sourceAccountID: string,
  resourceID: string
) =>
  useQuery<any, unknown, any, any[]>(
    ["get-insightvm-info", integrationType, sourceAccountID, resourceID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/asset-details?integration_type=${integrationType}&source_account_id=${sourceAccountID}&resource_id=${resourceID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled:
        integrationType === "INSIGHTVM" &&
        sourceAccountID !== "" &&
        resourceID !== "",
      keepPreviousData: false,
    }
  );
