import {
  faTrashCan,
  faNoteSticky,
  faComment,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { ReactFlowProvider } from "reactflow";
import ModalLayout from "src/layouts/ModalLayout";
import { decodeJWT, lastUpdatedAt } from "src/utils/general";
import FirewallSearch from "../../EvidenceType/FirewallSearch";
import MainSearch from "../../EvidenceType/MainSearch";
import DetailPanel from "../DetailPanel/DetailPanel";
import { DiaryType, UpdateEvidence } from "src/types/investigation";
import { useGeneralStore } from "src/stores/general";
import CPMSearch from "../../EvidenceType/CPMSearch";
import AlertAnalysis from "../../EvidenceType/AlertAnalysis";
import { useInvestigationStore } from "src/stores/investigation";
import {
  EditDiaryEvidence,
  DeleteDiaryEvidence,
  GetAutoGeneratedTags,
} from "src/services/investigation/diary/evidence/evidence";

const EvidenceDetail = ({
  diary,
  curEvidence,
  selectedEvidenceIDs,
  setSelectedEvidenceIDs,
}: {
  diary: DiaryType;
  curEvidence: any;
  selectedEvidenceIDs: string[];
  setSelectedEvidenceIDs: (selectedEvidenceIDs: string[]) => void;
}) => {
  const jwt = decodeJWT();

  const { env } = useGeneralStore();
  const {
    showEvidencePanel,
    setShowEvidencePanel,
    setSelectedEvidencePanelTab,
    selectedEvidenceID,
    setSelectedEvidenceID,
  } = useInvestigationStore();

  const [editEvidenceDetails, setEditEvidenceDetails] =
    useState<UpdateEvidence>({
      evidence_id: "",
      evidence_type: "",
      title: "",
      annotation: "",
    });
  const [deleteEvidenceID, setDeleteEvidenceID] = useState<string>("");

  const editEvidence = EditDiaryEvidence(env);
  const deleteEvidence = DeleteDiaryEvidence(env);
  const getAutoGeneratedTags = GetAutoGeneratedTags(env);

  const closedDiary = diary?.status === "CLOSED";

  const handleEditTitle = () => {
    setEditEvidenceDetails({
      ...editEvidenceDetails,
      evidence_id: "",
    });
    editEvidence.mutate({
      editEvidence: editEvidenceDetails,
    });
  };

  const handleOnClose = () => setDeleteEvidenceID("");

  return (
    <li
      key={curEvidence.evidence_id}
      className="grid gap-3 p-5 dark:bg-panel black-shadow rounded-sm"
    >
      <header className="grid lg:flex items-center justify-between gap-5 lg:gap-16">
        <article className="flex items-center gap-2">
          {curEvidence.author === jwt?.name && (
            <input
              type="checkbox"
              className="form-checkbox w-3 h-3 mt-2 self-start dark:ring-0 dark:text-signin dark:focus:border-signin focus:ring dark:focus:ring-offset-0 dark:focus:ring-signin focus:ring-opacity-50 rounded-full"
              checked={selectedEvidenceIDs.includes(curEvidence.evidence_id)}
              onChange={() => {
                if (selectedEvidenceIDs.includes(curEvidence.evidence_id))
                  setSelectedEvidenceIDs(
                    selectedEvidenceIDs.filter(
                      (evidenceID) => evidenceID !== curEvidence.evidence_id
                    )
                  );
                else
                  setSelectedEvidenceIDs([
                    ...selectedEvidenceIDs,
                    curEvidence.evidence_id,
                  ]);
              }}
            />
          )}
          {/* evidence title */}
          {editEvidenceDetails.evidence_id === curEvidence.evidence_id ? (
            <section className="flex items-center gap-2 ml-3">
              <button onClick={handleEditTitle}>
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-no hover:text-no/60 duration-100"
                />
              </button>
              <input
                type="input"
                spellCheck="false"
                autoComplete="off"
                value={editEvidenceDetails.title}
                onBlur={() => {
                  setEditEvidenceDetails({
                    ...editEvidenceDetails,
                    evidence_id: "",
                  });
                  editEvidence.mutate({
                    editEvidence: editEvidenceDetails,
                  });
                }}
                onChange={(e) =>
                  setEditEvidenceDetails({
                    ...editEvidenceDetails,
                    title: e.target.value,
                  })
                }
                className="px-4 py-1 lg:w-[20rem] xl:w-[30rem] text-base dark:bg-filter/30 focus:outline-none resize-none border-none focus:ring-0"
              />
            </section>
          ) : (
            <button
              disabled={closedDiary}
              className="px-2 py-1 text-left text-base break-words dark:disabled:hover:bg-transparent dark:hover:bg-filter/30 duration-100 rounded-sm"
              onClick={() =>
                setEditEvidenceDetails({
                  ...editEvidenceDetails,
                  evidence_id: curEvidence.evidence_id,
                  evidence_type: curEvidence.evidence_type,
                  title: curEvidence.title,
                })
              }
            >
              {curEvidence.title === "" ? "No title" : curEvidence.title}
            </button>
          )}
        </article>

        <article className="flex items-center justify-self-start lg:justify-self-end gap-5 py-2 px-0 lg:px-2 lg:py-2">
          {/* delete evidence */}
          {!closedDiary && (
            <>
              <button
                className="px-2 red-button"
                onClick={() => setDeleteEvidenceID(curEvidence.evidence_id)}
              >
                <FontAwesomeIcon icon={faTrashCan} className="w-3 h-3" />
              </button>
              <ModalLayout
                showModal={deleteEvidenceID === curEvidence.evidence_id}
                onClose={handleOnClose}
              >
                <section className="grid gap-5">
                  <h4 className="text-base text-center mb-3">
                    Are you sure you want to delete this piece of evidence?
                  </h4>
                  <section className="flex items-center gap-5 mx-auto text-sm">
                    <button
                      className="px-4 py-1 bg-gradient-to-b dark:from-filter dark:to-filter/60 dark:hover:from-filter dark:hover:to-filter/30 rounded-sm"
                      onClick={handleOnClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-1 bg-gradient-to-b dark:from-reset dark:to-reset/60 dark:hover:from-reset dark:hover:to-reset/30 rounded-sm"
                      onClick={() => {
                        setDeleteEvidenceID("");
                        deleteEvidence.mutate({
                          evidenceID: deleteEvidenceID,
                          evidenceType: curEvidence.evidence_type,
                        });
                      }}
                    >
                      Delete Evidence
                    </button>
                  </section>
                </section>
              </ModalLayout>
            </>
          )}
          <article className="flex items-center gap-5">
            {["Notes", "Discussion"].map((tab) => {
              return (
                <button
                  key={tab}
                  className="flex items-center gap-2 lg:pl-4 text-xs"
                  onClick={() => {
                    setShowEvidencePanel(!showEvidencePanel);
                    setSelectedEvidencePanelTab(tab);
                    setSelectedEvidenceID(curEvidence.evidence_id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={tab === "Notes" ? faNoteSticky : faComment}
                    className="p-1 w-3 h-3 dark:text-black dark:bg-white rounded-full"
                  />
                  {tab}
                </button>
              );
            })}
          </article>
          {/* evidence created by */}
          <article className="flex items-center gap-3 w-max text-xs dark:text-checkbox">
            <h4>Created</h4>
            <p className="dark:text-white">
              {lastUpdatedAt(curEvidence.created_at)}
            </p>
          </article>
        </article>
      </header>

      <section className="px-2 py-1 text-xs">
        {getAutoGeneratedTags?.data ? (
          getAutoGeneratedTags?.data?.length > 0 ? (
            <ul className="flex flex-wrap items-center gap-2">
              {getAutoGeneratedTags?.data.map((tag: string) => {
                return (
                  <li
                    key={tag}
                    className="px-4 py-1 dark:bg-filterrounded-full"
                  >
                    <p>{tag}</p>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No tags available</p>
          )
        ) : (
          <button
            className="flex items-center gap-2 p-1 dark:hover:bg-filter/30 duration-100 rounded-sm"
            onClick={() =>
              getAutoGeneratedTags.mutate({
                diaryID: curEvidence.diary_id,
                evidenceID: curEvidence.evidence_id,
                evidenceType: curEvidence.evidence_type,
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} />
            <p>Show tags</p>
          </button>
        )}
      </section>

      {curEvidence.evidence_type === "MAIN_GRAPH_SEARCH" && (
        <ReactFlowProvider>
          <MainSearch evidence={curEvidence} />
        </ReactFlowProvider>
      )}
      {curEvidence.evidence_type === "FIREWALL_SEARCH" && (
        <ReactFlowProvider>
          <FirewallSearch evidence={curEvidence} />
        </ReactFlowProvider>
      )}
      {curEvidence.evidence_type === "CPM_SEARCH" && (
        <ReactFlowProvider>
          <CPMSearch evidence={curEvidence} />
        </ReactFlowProvider>
      )}
      {curEvidence.evidence_type === "ALERT_ANALYSIS" && (
        <AlertAnalysis evidence={curEvidence} />
      )}
      <AnimatePresence exitBeforeEnter>
        {showEvidencePanel &&
          selectedEvidenceID === curEvidence.evidence_id && (
            <DetailPanel curEvidence={curEvidence} />
          )}
      </AnimatePresence>
    </li>
  );
};

export default EvidenceDetail;
