import React from "react";
import { KeyStringVal } from "src/types/general";

const ShowAllContent = ({
  showAllContent,
  setShowAllContent,
  sortedTable,
}: {
  showAllContent: string[];
  setShowAllContent: (showAllContent: string[]) => void;
  sortedTable: any;
}) => {
  return (
    <>
      {showAllContent && setShowAllContent && (
        <button
          className="px-4 py-1 text-sm dark:bg-checkbox/70 dark:hover:bg-filter/30 duration-100 rounded-full"
          onClick={() => {
            if (showAllContent.length === 0) {
              const generatedIDs = sortedTable?.reduce(
                (pV: string, cV: any) => [
                  ...pV,
                  ...cV.sub_sections.reduce(
                    (pV: string[], cV: KeyStringVal) => [
                      ...pV,
                      cV.generated_id,
                    ],
                    []
                  ),
                ],
                []
              );
              setShowAllContent(generatedIDs);
            } else setShowAllContent([]);
          }}
        >
          {showAllContent.length === 0 ? "Show" : "Hide"} all content
        </button>
      )}
    </>
  );
};

export default ShowAllContent;
