import { motion } from "framer-motion";
import React from "react";
import { pathVariants } from "../../constants/general";

const UnoLogo = () => {
  return (
    <a href="/">
      <svg
        stroke="currentColor"
        className="w-40 h-20 mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 84 32"
      >
        <path
          fill="white"
          d="M70.63 30.92c0-.12.02-.23.07-.33.04-.11.11-.2.18-.28a.75.75 0 0 1 .28-.18c.11-.04.22-.07.33-.07s.23.02.33.07c.11.04.2.11.28.18.08.08.14.17.18.28.04.11.07.22.07.33 0 .12-.02.23-.07.33-.04.11-.11.2-.18.28a.75.75 0 0 1-.28.18c-.11.04-.22.07-.33.07s-.23-.02-.33-.07a.898.898 0 0 1-.28-.18.75.75 0 0 1-.18-.28.725.725 0 0 1-.07-.33zm7.2-5.49h2.26v6.37h-2.26v-.71c-.48.6-1.13.91-1.95.91-.46 0-.89-.08-1.28-.25-.39-.17-.73-.41-1.02-.71-.29-.31-.51-.67-.68-1.08-.16-.41-.24-.86-.24-1.35 0-.45.08-.89.24-1.3.16-.41.38-.77.66-1.07a3.08 3.08 0 0 1 2.3-1c.79 0 1.45.28 1.97.83v-.64zM75 28.59c0 .21.04.4.11.58.07.18.18.33.3.47.13.13.28.24.46.31s.37.11.58.11c.2 0 .39-.04.56-.11.18-.07.33-.18.46-.31s.23-.29.31-.46c.08-.17.12-.36.12-.56 0-.2-.04-.39-.12-.56-.08-.18-.18-.33-.31-.47-.13-.13-.28-.24-.46-.31a1.55 1.55 0 0 0-.56-.11c-.21 0-.4.04-.58.11-.18.07-.33.18-.46.31s-.23.28-.3.45c-.07.17-.11.35-.11.55zm6.47-5.61c0-.17.03-.34.1-.49.07-.15.16-.29.27-.4.12-.12.25-.21.4-.27.15-.07.32-.1.49-.1.17 0 .34.03.49.1.15.07.29.16.4.27.12.12.21.25.27.4.07.15.1.32.1.49 0 .17-.03.34-.1.49-.07.15-.16.29-.27.4-.12.12-.25.21-.4.27-.15.07-.32.1-.49.1-.17 0-.34-.03-.49-.1-.15-.07-.29-.16-.4-.27a1.18 1.18 0 0 1-.27-.4c-.07-.15-.1-.31-.1-.49zm2.39 2.45v6.37h-2.25v-6.37h2.25zM38.66 6.47c-.53-1.39-1.43-2.64-2.71-3.75C33.86.9 31.22 0 28.03 0c-3.21 0-5.86.9-7.95 2.71-1.26 1.11-2.15 2.37-2.66 3.75-.53 1.55-.79 3.4-.79 5.53v7.86c0 4.42-1.74 6.63-5.23 6.63s-5.23-2.21-5.23-6.63V0H0v20c0 2.45.26 4.29.77 5.53.53 1.39 1.43 2.64 2.71 3.75C5.56 31.1 8.2 32 11.4 32c3.21 0 5.86-.9 7.95-2.71 1.26-1.11 2.15-2.37 2.66-3.75.53-1.55.79-3.4.79-5.53v-7.86c0-4.42 1.74-6.63 5.23-6.63s5.23 2.21 5.23 6.63V32h6.16V12c.01-2.45-.25-4.29-.76-5.53z"
        />
        <motion.g
          variants={pathVariants}
          initial="hidden"
          animate="visible"
          fill="none"
        >
          <motion.path
            variants={pathVariants}
            d="M57.7 32c-1.62 0-3.22-.24-4.75-.72a.494.494 0 0 1 .3-.94c1.44.45 2.94.68 4.46.68 6.21 0 11.86-3.91 14.06-9.73.1-.26.38-.38.63-.29.25.1.38.38.29.63C70.33 27.83 64.31 32 57.7 32zm-11.1-4.69a.47.47 0 0 1-.35-.15A15.877 15.877 0 0 1 41.7 16c0-8.82 7.18-16 16-16C66 0 73.01 6.48 73.65 14.75a.493.493 0 0 1-.98.08C72.07 7.07 65.49.98 57.7.98 49.42.98 42.68 7.72 42.68 16c0 3.93 1.52 7.65 4.27 10.47.19.19.19.51-.01.7-.1.09-.22.14-.34.14z"
          />
          <motion.path
            variants={pathVariants}
            d="M57.7 28.25c-6.75 0-12.25-5.49-12.25-12.25 0-1.48.26-2.92.78-4.3.1-.25.38-.38.63-.29.25.1.38.38.29.63-.47 1.26-.72 2.59-.72 3.95 0 6.21 5.05 11.26 11.26 11.26S68.96 22.21 68.96 16c0-1.29-.22-2.55-.64-3.76a.49.49 0 0 1 .3-.63c.26-.09.54.04.63.3.46 1.31.7 2.68.7 4.08-.01 6.76-5.5 12.26-12.25 12.26zM49.49 8.09a.47.47 0 0 1-.35-.15.492.492 0 0 1 .01-.7c2.3-2.25 5.34-3.49 8.55-3.49 3.1 0 6.06 1.17 8.34 3.28.2.18.21.5.02.7a.49.49 0 0 1-.69.03 11.215 11.215 0 0 0-7.67-3.02c-2.95 0-5.75 1.14-7.86 3.21-.1.09-.23.14-.35.14z"
          />
          <motion.path
            variants={pathVariants}
            d="M57.7 24.49c-1.46 0-2.9-.38-4.16-1.09a.491.491 0 0 1-.19-.67c.13-.24.43-.32.67-.19 1.12.63 2.39.96 3.68.96 2.59 0 4.97-1.32 6.36-3.52.14-.23.45-.3.68-.15.23.14.3.45.15.68a8.467 8.467 0 0 1-7.19 3.98zm-6.72-3.66a.49.49 0 0 1-.41-.22A8.454 8.454 0 0 1 49.21 16c0-4.68 3.81-8.49 8.49-8.49s8.49 3.81 8.49 8.49c0 .26-.01.51-.03.77-.03.27-.28.47-.53.45a.493.493 0 0 1-.45-.53c.02-.22.03-.45.03-.68 0-4.14-3.37-7.51-7.51-7.51s-7.51 3.37-7.51 7.51c0 1.45.42 2.86 1.2 4.07.15.23.08.53-.15.68-.08.05-.17.07-.26.07z"
          />
          <motion.path
            variants={pathVariants}
            d="M57.7 21.01c-.47 0-.93-.07-1.38-.19a.494.494 0 1 1 .27-.95c.36.1.73.15 1.11.15 1.43 0 2.77-.77 3.49-2.02.14-.24.44-.31.67-.18.24.14.32.44.18.67a5.032 5.032 0 0 1-4.34 2.52zm-3.11-1.25c-.12 0-.24-.05-.34-.14a5.023 5.023 0 0 1-1.56-3.63c0-2.76 2.25-5.01 5.01-5.01s5 2.26 5 5.02a.49.49 0 0 1-.98 0 4.02 4.02 0 0 0-8.04 0c0 1.11.44 2.15 1.25 2.91.2.19.2.5.02.7-.1.1-.23.15-.36.15z"
          />
          <motion.path
            variants={pathVariants}
            d="M57.7 18.5c-.17 0-.34-.02-.5-.05-1.16-.24-2-1.27-2-2.45 0-.13.01-.25.03-.37.04-.27.3-.45.56-.41.27.04.45.29.41.56-.01.07-.02.15-.02.22a1.521 1.521 0 0 0 1.52 1.52.49.49 0 0 1 0 .98zm1.73-.99c-.09 0-.17-.02-.25-.07a.502.502 0 0 1-.18-.67c.1-.17.17-.36.19-.56.04-.27.29-.46.55-.42.27.04.46.28.42.55-.04.33-.15.64-.32.93-.07.15-.24.24-.41.24zM59.04 15a.46.46 0 0 1-.33-.13c-.44-.4-1.11-.5-1.65-.25-.25.11-.54 0-.65-.24a.487.487 0 0 1 .24-.65 2.52 2.52 0 0 1 2.71.42.484.484 0 0 1-.32.85z"
          />
        </motion.g>
      </svg>
    </a>
  );
};

export default UnoLogo;
