import React from "react";
import ThirdPartyControlFilter from "src/components/Filter/ThirdPartyRisk/ThirdPartyControlFilter";
import { GetControlFilters } from "src/services/third-party-risk/vendor-assessment";

const ControlFilters = ({
  reviewID,
  context,
  domain,
  level,
  setContext,
  setDomain,
  setLevel,
  controls,
}: {
  reviewID: string;
  context: string[];
  domain: string[];
  level: string[];
  setContext: (context: string[]) => void;
  setDomain: (domain: string[]) => void;
  setLevel: (level: string[]) => void;
  controls: any;
}) => {
  const { data: controlFilters } = GetControlFilters(reviewID);

  const handleReset = () => {
    setContext([]);
    setDomain([]);
    setLevel([]);
    if (controls.data)
      controls.mutate({
        context: [],
        domain: [],
        level: [],
      });
  };

  return (
    <>
      {controlFilters && (
        <article className="grid gap-3 text-sm">
          <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
            <button
              className="dark:hover:text-checkbox/60 duration-100"
              onClick={() => {
                setContext(controlFilters.domain);
                setDomain(controlFilters.context);
                setLevel(controlFilters.level);
              }}
            >
              Select All
            </button>
            <button
              className="pl-5 dark:hover:text-checkbox/60 duration-100"
              onClick={() => {
                setContext([]);
                setDomain([]);
                setLevel([]);
              }}
            >
              Deselect All
            </button>
          </article>
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Context"
            keyName="domain"
            inputs={context}
            setInputs={setContext}
          />
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Domain"
            keyName="context"
            inputs={domain}
            setInputs={setDomain}
          />
          <ThirdPartyControlFilter
            reviewID={reviewID}
            label="Level"
            keyName="level"
            inputs={level}
            setInputs={setLevel}
          />
          <article className="flex items-center gap-5">
            <button
              className="px-3 py-1 red-gradient-button"
              onClick={handleReset}
            >
              Reset
            </button>
            <button
              className="px-3 py-1 gradient-button"
              onClick={() =>
                controls.mutate({
                  context: context,
                  domain: domain,
                  level: level,
                })
              }
            >
              Apply
            </button>
          </article>
        </article>
      )}
    </>
  );
};

export default ControlFilters;
