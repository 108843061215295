/* eslint-disable react-hooks/exhaustive-deps */
import KeyValuePair from "../../../../components/General/KeyValuePair";
import { useEffect, useState } from "react";
import { periods, severityColors } from "src/constants/summaries";
import { GetVRCVEDetails } from "src/services/summaries/vulnerability-risks";
import TablePagination from "src/components/General/TablePagination";
import { useSummaryStore } from "src/stores/summaries";
import { useGeneralStore } from "src/stores/general";
import { pageSize } from "src/constants/general";
import ServiceDetail from "./ServiceDetail";
import CVENotes from "src/components/CVE/CVENotes";
import { KeyStringVal } from "src/types/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

const CVEDetail = ({
  selectedSource,
  selectedVersion,
  selectedIntegrationType,
  selectedSourceAccountID,
}: {
  selectedSource: string;
  selectedVersion: string;
  selectedIntegrationType: string;
  selectedSourceAccountID: string;
}) => {
  const {
    period,
    selectedVRSeverity,
    selectedVRCVE,
    selectedVRPage,
    setSelectedVRPage,
  } = useSummaryStore();
  const { spotlightSearchString } = useGeneralStore();

  const [query, setQuery] = useState<string>("");

  const { data: cveDetail } = GetVRCVEDetails(
    period,
    selectedVRCVE,
    selectedSource,
    selectedVersion,
    selectedIntegrationType,
    selectedSourceAccountID,
    selectedVRPage,
    pageSize
  );

  const filteredServices =
    query !== ""
      ? cveDetail?.data.filter((service: KeyStringVal) =>
          service.service_name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
      : cveDetail?.data;
  const totalCount = cveDetail?.pager.total_results || 0;
  const totalPages = cveDetail?.pager.num_pages || 0;
  const beginning =
    selectedVRPage === 1 ? 1 : pageSize * (selectedVRPage - 1) + 1;
  const end =
    selectedVRPage === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    if (cveDetail?.data && spotlightSearchString !== "")
      document.getElementById(`${spotlightSearchString}`)?.scrollIntoView();
  }, [cveDetail]);

  useEffect(() => {
    setSelectedVRPage(1);
  }, [selectedVRSeverity]);

  return (
    <section className="grid content-start gap-5 p-4 w-full h-full dark:bg-panel black-shadow">
      <header className="flex items-center gap-5">
        <img src="/general/vuln.svg" alt="cve" className="w-7 h-7" />
        <article className="relative">
          <h4 className="text-lg dark:text-white">{selectedVRCVE}</h4>
          <a
            href={`/cves/details?cve_id=${selectedVRCVE}`}
            className="absolute -right-5 -top-3"
          >
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="dark:text-checkbox"
            />
          </a>
        </article>
        <p
          className={`p-2 ml-5 w-max text-sm ${
            severityColors[selectedVRSeverity.toLowerCase()]
          }`}
        >
          {selectedVRSeverity}
        </p>
        <CVENotes cveID={selectedVRCVE} />
      </header>

      {cveDetail && (
        <article className="flex items-center gap-5">
          <article className="flex items-center gap-2">
            <article className="group relative flex flex-wrap items-center gap-2 mx-auto">
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="w-3 h-3 dark:text-checkbox z-0 focus:outline-none"
              />
              <p className="hidden group-hover:block absolute top-6 left-0 p-3 w-60 text-left text-sm dark:text-white dark:bg-search overflow-hidden rounded-sm z-10">
                Counts include resources that are currently running or have been
                active in <span className="lowercase">{periods[period]}</span>
              </p>
            </article>
            <KeyValuePair
              label="Live Resources"
              value={cveDetail.summary.live_services}
            />
          </article>
          <KeyValuePair
            label="First Seen"
            value={
              cveDetail.summary.first_seen === 0
                ? "N/A"
                : Number(cveDetail.summary.first_seen) * 1000
            }
          />
        </article>
      )}
      <section className="grid gap-5 w-full">
        {cveDetail ? (
          cveDetail.data?.length > 0 ? (
            <section className="grid gap-5">
              <header className="flex items-start justify-between gap-10">
                <input
                  type="filter"
                  autoComplete="off"
                  spellCheck="false"
                  placeholder="Search service"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="px-5 w-2/5 h-8 text-xs placeholder:text-secondary placeholder:text-xs placeholder:font-medium dark:text-white dark:disabled:cursor-not-allowed border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none dark:bg-search rounded-sm"
                />
                <TablePagination
                  totalPages={totalPages}
                  beginning={beginning}
                  end={end}
                  totalCount={totalCount}
                  pageNumber={selectedVRPage}
                  setPageNumber={setSelectedVRPage}
                />
              </header>
              <ul className="relative grid gap-3">
                {filteredServices?.map((row: any, index: number) => {
                  return (
                    <ServiceDetail
                      key={index}
                      row={row}
                      selectedIntegrationType={selectedIntegrationType}
                      selectedSourceAccountID={selectedSourceAccountID}
                    />
                  );
                })}
              </ul>
            </section>
          ) : (
            <p className="text-sm">No data available</p>
          )
        ) : null}
      </section>
    </section>
  );
};

export default CVEDetail;
