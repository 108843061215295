import React from "react";
import Severities from "./Severities";
import SeverityDistribution from "./SeverityDistribution";
import SummaryInfo from "./SummaryInfo";

const Overview = ({
  vrSummary,
  severityDistribution,
}: {
  vrSummary: any;
  severityDistribution: any;
}) => {
  return (
    <header className="grid gap-10 p-4 dark:bg-panel black-shadow">
      {vrSummary && (
        <article className="grid lg:grid-cols-3 gap-5 px-16">
          <SummaryInfo vrSummary={vrSummary} />
          <Severities vrSummary={vrSummary} />
        </article>
      )}
      <SeverityDistribution severityDistribution={severityDistribution} />
    </header>
  );
};

export default Overview;
