import React from "react";
import DonutChart from "src/components/Chart/DonutChart";
import { sortNumericData } from "src/utils/general";

const Severities = ({
  title,
  vrSummary,
}: {
  title?: string;
  vrSummary: any;
}) => {
  const sortedLayers = sortNumericData(vrSummary?.data, "order", "desc");

  return <DonutChart data={sortedLayers} xKey="severity" title={title} />;
};

export default Severities;
