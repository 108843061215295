import React, { useState } from "react";
import StackedAreaChart from "src/components/Chart/StackedAreaChart";

const SeverityDistribution = ({
  severityDistribution,
}: {
  severityDistribution: any;
}) => {
  const [sectionProps, setSectionProps] = useState({});

  return (
    <>
      {severityDistribution && (
        <StackedAreaChart
          data={severityDistribution}
          xKey="timestamp"
          yLabel="Count"
          hasSeverity
          sectionProps={sectionProps}
          setSectionProps={setSectionProps}
          placeholder="No images with CVEs are currently deployed in your infrastrucure"
        />
      )}
    </>
  );
};

export default SeverityDistribution;
