import {
  faNoteSticky,
  faChevronCircleDown,
  faChevronCircleUp,
  faDiagramProject,
  faInfo,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ViewInGraph from "src/components/Button/ViewInGraph";
import { severityColors } from "src/constants/summaries";
import { GetQueryLookup } from "src/services/graph/search";
import { useGraphStore } from "src/stores/graph";
import { QueryLookup } from "src/types/graph";
import { convertToDate, lastUpdatedAt } from "src/utils/general";
import { handleViewSnapshot } from "src/utils/graph";

const ServiceDetail = ({
  row,
  selectedIntegrationType,
  selectedSourceAccountID,
}: {
  row: any;
  selectedIntegrationType: string;
  selectedSourceAccountID: string;
}) => {
  const navigate = useNavigate();

  const {
    setNavigationView,
    setGraphSearch,
    setGraphSearching,
    setGraphSearchString,
    setSnapshotTime,
    setElementType,
    setSelectedNode,
    setSelectedEdge,
    setGraphInfo,
    graphInfo,
    setSelectedPanelTab,
  } = useGraphStore();

  const [showOtherCVEs, setShowOtherCVEs] = useState<boolean>(false);

  const queryLookup = GetQueryLookup();

  const handleQueryString = (requestData: QueryLookup, row: any) => {
    queryLookup.mutate(
      {
        requestData: requestData,
      },
      {
        onSuccess: (queryString) =>
          handleViewSnapshot(
            queryString,
            setNavigationView,
            setGraphSearch,
            setGraphSearching,
            setGraphSearchString,
            navigate,
            setSnapshotTime,
            Number(row.live_service_last_seen)
          ),
      }
    );
  };

  return (
    <li
      key={row.service_name}
      className="grid gap-3 p-5 text-xs dark:bg-expand black-shadow rounded-sm"
    >
      <header className="grid lg:flex items-start justify-between gap-5 lg:gap-20">
        <article className="grid gap-3">
          <h4 className="text-base">{row.service_name}</h4>
          {selectedIntegrationType !== "INSIGHTVM" && (
            <article className="flex items-center gap-10 dark:text-white">
              <ViewInGraph
                requestData={{
                  query_type: "view_in_graph",
                  id: row.service_name,
                }}
                curSnapshotTime={row.live_service_last_seen}
              />
              <button
                className="flex flex-wrap items-center gap-2 w-max"
                onClick={() =>
                  handleQueryString(
                    {
                      query_type: "view_in_graph",
                      id: row.image,
                    },
                    row
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faDiagramProject}
                  className="dark:text-checkbox"
                />
                <p>View Latest Image</p>
              </button>
              <button
                className="flex flex-wrap items-center gap-2 w-max"
                onClick={() =>
                  handleQueryString(
                    {
                      query_type: "blast_radius",
                      id: row.service_name,
                    },
                    row
                  )
                }
              >
                <img
                  src="/dashboard/firewall/blast-radius.svg"
                  alt="blast radius"
                />
                <p>View Blast Radius</p>
              </button>
            </article>
          )}
        </article>
        <article className="flex items-center justify-self-start lg:justify-self-end gap-10 py-2 px-0 lg:px-2">
          <article className="flex items-center gap-5">
            {["Info", "Notes"].map((tab) => {
              return (
                <button
                  key={tab}
                  className="flex items-center gap-2 lg:pl-4"
                  onClick={() => {
                    setElementType("node");
                    setSelectedNode({
                      id: row.service_name,
                      data: {
                        id: row.service_name,
                        integrationType: selectedIntegrationType,
                        sourceAccountID: selectedSourceAccountID,
                      },
                    });
                    setSelectedEdge(undefined);
                    setGraphInfo({
                      ...graphInfo,
                      showPanel: true,
                    });
                    setSelectedPanelTab(tab);
                    setSnapshotTime(convertToDate(row.live_service_last_seen));
                  }}
                >
                  <FontAwesomeIcon
                    icon={tab === "Notes" ? faNoteSticky : faInfo}
                    className="p-1 w-3 h-3 dark:text-black dark:bg-white rounded-full"
                  />
                  {tab}
                </button>
              );
            })}
          </article>
          {/* evidence created by */}
          <article className="flex items-center gap-3 w-max dark:text-checkbox">
            <h4>Last Updated At</h4>
            <p className="dark:text-white">
              {lastUpdatedAt(row.live_service_last_seen)}
            </p>
          </article>
        </article>
      </header>
      <section className="flex items-center gap-1">
        <h4>Context:</h4>
        <ul className="flex flex-wrap items-center gap-2">
          {Object.entries(row.context).map((keyVal: [string, any]) => {
            return (
              <li
                key={keyVal[0]}
                className="relative group grid gap-1 py-2 px-4 cursor-pointer dark:hover:bg-filter/30 duration-100 border-1 dark:border-checkbox/30 rounded-md"
                onClick={() => {
                  if (keyVal[1].action === "graph")
                    handleViewSnapshot(
                      keyVal[1].result,
                      setNavigationView,
                      setGraphSearch,
                      setGraphSearching,
                      setGraphSearchString,
                      navigate,
                      setSnapshotTime,
                      Number(row.live_service_last_seen)
                    );
                }}
              >
                {keyVal[1].action === "graph" && (
                  <img
                    src={`/graph/nodes/${selectedIntegrationType.toLowerCase()}/${keyVal[0].toLowerCase()}.svg`}
                    alt={keyVal[0]}
                    className="mx-auto w-10 h-10"
                  />
                )}
                <p className="text-center">{keyVal[0]}</p>
                {keyVal[1].action === "hover" ? (
                  keyVal[1].type === "json" ? (
                    <article className="absolute bottom-10 hidden group-hover:grid gap-2 p-4 dark:bg-card rounded-md z-10">
                      {keyVal[1].result.map((value: string, index: number) => {
                        return (
                          <span key={index} className="w-max">
                            • {value}
                          </span>
                        );
                      })}
                    </article>
                  ) : (
                    keyVal[1].type === "text" && (
                      <p className="absolute bottom-10 hidden group-hover:grid p-4 w-max dark:bg-card rounded-md z-10">
                        {keyVal[1].result}
                      </p>
                    )
                  )
                ) : null}
              </li>
            );
          })}
        </ul>
      </section>
      <button
        className="flex items-center gap-2 w-max"
        onClick={() => setShowOtherCVEs(!showOtherCVEs)}
      >
        <article className="group relative flex flex-wrap items-center gap-2 mx-auto">
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="w-3 h-3 dark:text-checkbox z-0 focus:outline-none"
          />
          <p className="hidden group-hover:block absolute top-6 left-0 p-3 w-60 text-left text-sm dark:text-white dark:bg-search overflow-hidden rounded-sm z-10">
            Other CVEs that were found on this resource
          </p>
        </article>
        <p>{showOtherCVEs ? "Hide" : "Show"} Other CVEs</p>
        <FontAwesomeIcon
          icon={showOtherCVEs ? faChevronCircleUp : faChevronCircleDown}
          className="dark:text-checkbox"
        />
      </button>
      {showOtherCVEs && (
        <section className="flex justify-between gap-5 px-3 py-2 w-full max-h-[15rem] text-xs overflow-auto scrollbar">
          {Object.keys(row.other_vulnerabilities).map((severity) => {
            return (
              <article className="grid gap-3 w-full">
                <h4>{severity}</h4>
                <ul className="grid content-start gap-1 w-full">
                  {row.other_vulnerabilities[severity].map((cve: string) => {
                    return (
                      <li
                        key={cve}
                        className={`px-2 py-1 w-full cursor-pointer ${
                          severityColors[severity.toLowerCase()]
                        }`}
                        onClick={() => navigate(`/cves/details?cve_id=${cve}`)}
                      >
                        <p>{cve}</p>
                      </li>
                    );
                  })}
                </ul>
              </article>
            );
          })}
        </section>
      )}
    </li>
  );
};

export default ServiceDetail;
